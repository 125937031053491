<template>
  <b-overlay :show="isLoading">
    <b-row class="mb-3" v-for="item of rules" :class="{'exist': item.exist}"
           :title="!item.exist? 'This rule doesn\'t exist, create it to found this car':'Exist or is the same'">
      <b-col cols="12" class="capitalize">
        {{ item.field.replace('_', ' ') }}
      </b-col>
      <b-col cols="5">
        <b-input readonly v-model="item.result_value" :state="item.exist"/>
      </b-col>
      <b-col cols="5">
        <b-input readonly :state="item.exist"
                 :value="Array.isArray(item.found_value)? item.found_value.join('; ') : item.found_value"/>
      </b-col>
      <b-col cols="2">
        <b-button title="Create rule" :disabled="item.exist" @click="createRule(item)"
                  v-tippy="{ placement : 'top',  arrow: true }" :content="item.exist? 'Exist or is the same': 'Create Rule'">
          <i class="material-icons">check_box</i>
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {post} from "@/services/api";

export default {
  name: "SearchRules",
  data: () => ({
    rules: [],
    isLoading: true
  }),
  methods: {
    createRule(item) {
      post('vin-search-rules', item, true).then(() => {
        item.exist = true;
      })
    },
    setData(rules) {
      post('vin-search-rules/find-rules', rules).then(({data}) => {
        this.rules = data;
        this.isLoading = false;
      })
    }
  }
}
</script>

<style scoped>
.exist {

}
</style>
