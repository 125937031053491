<template>
  <b-container class="main-content-container px-4" fluid v-if="model">
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="12">
        <h3 class="page-title">{{ model.vehicle_identification_no }}</h3>
        <span class="text-uppercase page-subtitle">{{ 'Vin search result' }}</span>
      </b-col>
    </b-row>
    <b-card title="Search details">
      <b-row>
        <b-col lg="4">
          <b-list-group>
            <b-list-group-item v-for="row of fieldsView" class="d-flex justify-content-between">
              <span :title="row.title">{{ row.label }}</span>
              <span>{{ Array.isArray(model[row.key]) ? model[row.key].join(', ') : model[row.key] }}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="8">
          <h5 v-if="!model.has_results">Create a new vehicle in our database / Create rules to find a vehicle</h5>
          <div class="text-right mb-2">
            <div class="w-25 ml-auto">
              <b-form-input v-model="filter" placeholder="search"/>
            </div>
          </div>
          <b-table v-if="vehicles.list.length" bordered small hover :items="vehicles.list" :fields="fieldsSuggest"
                   primary-key="id" :filter="filter">
            <template #cell(engineCode)="{item}">
              {{ JSON.parse(item.engineCode).join(', ') }}
            </template>
            <template #cell(options)="{item}">
              <b-button-group v-if="!model.has_results">
                <b-button @click="openView(item.id)" v-tippy="{ placement : 'top',  arrow: true }"
                          :content="'Create a copy'">
                  <i class="material-icons">content_copy</i>
                </b-button>
                <b-button @click="createSearchRules(item)" v-tippy="{ placement : 'top',  arrow: true }"
                          :content="'Create searching rules'">
                  <i class="material-icons">manage_search</i></b-button>
              </b-button-group>
              <span v-else>
                <b-button @click="view(item.id)" v-tippy="{ placement : 'top',  arrow: true }" :content="'View'"
                          size="sm">
                  <i class="material-icons">visibility</i>
                </b-button>
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal size="lg" ref="modal"
             :title="model.has_results? 'Vehicle details':`Create a new vehicle for: ${model.make} - ${model.model}`"
             hide-footer>
      <view-vehicle ref="vehicleView"/>
    </b-modal>
    <b-modal ref="modelRules" title="Create rules to find this vehicle." hide-footer>
      <search-rules ref="rules" @close="reSearch"/>
    </b-modal>
  </b-container>
</template>

<script>
import {get} from "@/services/api";
import ViewVehicle from "@/components/vehicles/ViewVehicle";
import SearchRules from "@/components/VinSearch/SearchRules";

export default {
  name: "ViewVin",
  components: {SearchRules, ViewVehicle},
  data: () => ({
    id: null,
    model: null,
    fieldsView: [
      {key: 'vehicle_identification_no', label: 'VIN'},
      {key: 'make', label: 'Brand'},
      {key: 'model', label: 'Model', title: 'Model to find in our database'},
      {key: 'model_description', label: 'Model description'},
      {key: 'model_year', label: 'Model Year'},
      {key: 'date_of_production', label: 'Date of production'},
      {key: 'engine_code', label: 'Engine code'},
      {key: 'dsg_code', label: 'DSG Prefix'},
      {key: 'dsg', label: 'DSG'},
      {key: 'ecu_code', label: 'ECU code'},
    ],
    vehicles: {},
    fieldsSuggest: [
      'id', 'make', 'model', 'generation', 'engine', 'dsg', 'engineCode', 'options'
    ],
    filter: '',
  }),
  methods: {
    load() {
      this.$store.commit('loaderManager', true)
      get(`vin-search/${this.id}`)
        .then(({data}) => {
          this.model = data.model;
          this.model.dsg = this.model.dsg.join(', ')
          this.vehicles = data.vehicles;
          //this.reSearch()
        }).finally(() => this.$store.commit('loaderManager', false))
    },
    openView(id) {
      this.$refs.modal.show();
      this.$nextTick(() => {
        this.$refs.vehicleView.loadVehicle(id);
        this.$refs.vehicleView.setToCopy({
          make: this.model.make,
          model: this.model.model,
          engineCode: [this.model.engine_code],
          engine: '',
          generation: ''
        })
        this.$refs.vehicleView.$on('created', () => {
          this.$refs.modal.hide();
          this.reSearch();
          this.load()
        })
      })
    },
    view(id) {
      this.$refs.modal.show();
      this.$nextTick(() => {
        this.$refs.vehicleView.loadVehicle(id);
      })
    },
    createSearchRules(vehicle) {
      let rules = [
        {field: 'make', result_value: this.model.make, found_value: vehicle.make},
        {field: 'model', result_value: this.model.model, found_value: vehicle.model},
        {
          field: 'engine_code',
          result_value: this.model.engine_code,
          found_value: JSON.parse(vehicle.engineCode)[0],
          options: JSON.parse(vehicle.engineCode)
        },
      ];
      this.$refs.modelRules.show();
      this.$refs.modelRules.$once('hide', () => this.reSearch())
      this.$nextTick(() => {
        this.$refs.rules.setData(rules)
      })
    },
    reSearch() {
      get(`vin-search/re-search/${this.id}`).then(({data}) => {
        this.model = data;
        if (this.model.has_results) {
          this.load();
        }
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
  }
}
</script>

<style scoped>

</style>
